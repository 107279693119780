import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  TextField,
  Grid,
  Box,
  CircularProgress,
  Autocomplete,
  Alert,
  Typography,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SelectedMembers from "./SelectedMembers";
import ServiceDesign from "./ServiceDesign";
import ProjectNameInput from "./ProjectNameInput";
import { calculateAvailability } from "./availabilityCalculator";

const ProjectForm = ({
  projectNumber,
  projectName,
  onProjectNameChange,
  projectDescription,
  onDescriptionChange,
  selectedCustomer,
  onCustomerChange,
  customerOptions,
  isLoadingLocations,
  locationError,
  startDate,
  onStartDateChange,
  endDate,
  onEndDateChange,
  userEmail,
  error,
  setError,
  MAX_PROJECT_LENGTH,
  MAX_DESCRIPTION_LENGTH,
  selectedMembers,
  onRemoveMember,
  technicians,
  ws,
  onServiceChange,
  locations,
  selectedServices
}) => {
  const [customerBuildings, setCustomerBuildings] = useState(null);

  const isCustomerSelected = Boolean(selectedCustomer);

  const handleCustomerSelect = (event, newValue) => {
    onCustomerChange(event, newValue);
    if (newValue && locations[newValue]) {
      setCustomerBuildings(locations[newValue].BUILDINGS || []);
    } else {
      setCustomerBuildings(null);
    }
  };


  // Handle date changes with schedule updates
  const handleStartDateChange = (newDate) => {
    if (!newDate) {
      onStartDateChange(null);
      onEndDateChange(null);
      return;
    }
    onStartDateChange(newDate);
  };

  const handleEndDateChange = (newDate) => {
    if (!newDate) {
      onEndDateChange(null);
      return;
    }

    if (!startDate) {
      return;
    }

    if (newDate.isBefore(startDate)) {
      return;
    }

    onEndDateChange(newDate);
  };

  return (
    <>
      {error && !error.includes("Project name") && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <ProjectNameInput
        projectName={projectName}
        onProjectNameChange={onProjectNameChange}
        maxLength={MAX_PROJECT_LENGTH}
        error={error && error.includes("Project name") ? error : ""}
        onValidityChange={(isValid) => {
          if (!isValid && setError) {
            setError("Project name is invalid or unavailable");
          } else if (setError) {
            setError("");
          }
        }}
      />

      <TextField
        fullWidth
        label="Project Owner"
        value={userEmail || ""}
        margin="normal"
        disabled
        sx={{ mt: 2, mb: 2 }}
      />

      {isLoadingLocations ? (
        <Box display="flex" justifyContent="center" sx={{ my: 2 }}>
          <CircularProgress />
        </Box>
      ) : locationError ? (
        <Alert severity="error" sx={{ mb: 2 }}>
          {locationError}
        </Alert>
      ) : (
        <Autocomplete
          fullWidth
          value={selectedCustomer}
          onChange={handleCustomerSelect}
          options={customerOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Customer"
              required
              sx={{ mt: 2 }}
              error={!!error && error.includes("Customer")}
              helperText={!selectedCustomer ? "Please select a customer to proceed": ""}
            />
          )}
          loading={isLoadingLocations}
          loadingText="Loading customers..."
          noOptionsText="No customers found"
          renderOption={(props, option, state) => {
            const { key, ...otherProps } = props;
            return (
              <li key={option} {...otherProps}>
                <Typography noWrap>{option}</Typography>
              </li>
            );
          }}
        />
      )}

      <TextField
        fullWidth
        label="Project Description"
        multiline
        rows={4}
        value={projectDescription}
        onChange={onDescriptionChange}
        margin="normal"
        helperText={`${
          MAX_DESCRIPTION_LENGTH - projectDescription.length
        } characters remaining`}
        inputProps={{
          maxLength: MAX_DESCRIPTION_LENGTH,
        }}
        sx={{ mt: 2 }}
      />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={handleStartDateChange}
              sx={{ width: "100%" }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: !!error && error.includes("start"),
                  placeholder: "Select start date",
                },
              }}
              clearable
              format="MM/DD/YYYY"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={handleEndDateChange}
              minDate={startDate}
              sx={{ width: "100%" }}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: !!error && error.includes("end"),
                  placeholder: "Select end date",
                },
              }}
              clearable
              format="MM/DD/YYYY"
              disabled={!startDate}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>

            {!isCustomerSelected ? (
        <Alert severity="info" sx={{ mt: 3 }}>
          Please select a customer to add services to the project.
        </Alert>
      ) : (
        <ServiceDesign
          availableBuildings={customerBuildings}
          onServiceChange={onServiceChange}
          selectedServices={selectedServices}
        />
      )}

      <Box sx={{ mt: 3 }}>
        <SelectedMembers
          members={selectedMembers}
          onRemoveMember={onRemoveMember}
        />
      </Box>
    </>
  );
};

export default ProjectForm;
