import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Alert,
  Grid,
  Button,
  IconButton,
  Tooltip,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  useTheme,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Card,
  CardContent,
  CardHeader,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import SettingsIcon from "@mui/icons-material/Settings";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import eServicesDatabase from "../Data/eServicesDatabase.json";
import eTasksDatabase from "../Data/eTasksDatabase.json";
import BuildingSelector from "./BuildingSelector";
import { v4 as uuidv4 } from "uuid";
import ServiceCard from "./ServiceCard";

const ServiceDesign = ({ onServiceChange, availableBuildings }) => {
  const [services, setServices] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);
  const [currentService, setCurrentService] = useState(null);
  const [currentTasks, setCurrentTasks] = useState([]);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [selectedZone, setSelectedZone] = useState("");
  const [selectedFloor, setSelectedFloor] = useState("");
  const [serviceQuantity, setServiceQuantity] = useState(1);
  const [editingServiceId, setEditingServiceId] = useState(null);

  const theme = useTheme();

  useEffect(() => {
    console.log("Selected Floor Change:", selectedFloor);
  }, [selectedFloor]);

  useEffect(() => {
    try {
      const flattenedServices = eServicesDatabase.Categories.flatMap(
        (category) => {
          const categoryName = Object.keys(category)[0];
          return category[categoryName].map((service) => ({
            ...service,
            serviceID: service.serviceID,
            category: categoryName,
          }));
        }
      );

      setServices(flattenedServices);
      setTasks(eTasksDatabase.Tasks);
    } catch (error) {
      setError("Failed to process services and tasks data");
      console.error("Error processing data:", error);
    }
  }, []);

  const filteredTasks = tasks.filter(
    (task) =>
      task.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      task.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleServiceSelect = (service) => {
    setCurrentService(service);
    if (isEditing) {
      setCurrentTasks([]);
    }
  };

  const canUpdateService = () => {
    if (isEditing) {
      return currentTasks.length > 0;
    }
    return currentService && currentTasks.length > 0;
  };

  const handleEditService = (serviceEntry) => {
    if (!serviceEntry || !serviceEntry.service) {
      console.error("Invalid service entry for editing:", serviceEntry);
      return;
    }

    setEditingServiceId(serviceEntry.service.currentService.serviceID);

    // Set all the values for editing
    setCurrentService(serviceEntry.service.currentService);
    setCurrentTasks(serviceEntry.tasks || []);
    setServiceQuantity(serviceEntry.quantity || 1);

    if (serviceEntry.location) {
      setSelectedBuilding(
        availableBuildings.find(
          (b) => b["Building Name"] === serviceEntry.location.building
        )
      );
      setSelectedZone(serviceEntry.location.zone || "");
      setSelectedFloor(serviceEntry.location.floor || "");
    }

    setIsEditing(true);
    setIsAddDialogOpen(true);
  };

  const handleTaskToggle = (task) => {
    setCurrentTasks((current) => {
      const exists = current.find((t) => t.ID === task.ID);
      if (exists) {
        return current.filter((t) => t.ID !== task.ID);
      }
      return [...current, task];
    });
  };

  const handleAddService = () => {
    if (!currentService || currentTasks.length === 0) {
      setError("Please select a service and at least one task");
      return;
    }

    const newServiceId = uuidv4();

    const serviceEntry = {
      service: {
        currentService: {
          ...currentService,
          serviceID: isEditing ? editingServiceId : newServiceId,
          "Service Type": currentService["Service Type"] || "Unknown Service",
          category: currentService.category || "Uncategorized",
          Description: currentService.Description || "",
        },
      },
      tasks: currentTasks.map((task) => ({
        ID: task.ID,
        name: task.name || "Unnamed Task",
        description: task.description || "",
        status: {
          New: serviceQuantity,
          Assigned: 0,
          InProgress: 0,
          OnHold: 0,
          Resolved: 0,
          Closed: 0,
          Cancelled: 0,
          Completed: 0,
        },
      })),
      lastUpdated: [
        {
          User: "",
          Time: "",
          UpdateType: "",
        },
      ],
      status: {
        New: serviceQuantity,
        Assigned: 0,
        InProgress: 0,
        OnHold: 0,
        Resolved: 0,
        Closed: 0,
        Cancelled: 0,
        Completed: 0,
      },
      quantity: serviceQuantity || 1,
      location: selectedBuilding
        ? {
            building: selectedBuilding["Building Name"] || "",
            buildingNumber: selectedBuilding["Building Number"] || "",
            zone: selectedZone || "",
            floor: selectedFloor || "1",
            buildingCoordinator: selectedBuilding["Building Coordinator"] || "",
            coordinatorEmail: selectedBuilding["Email"] || "",
            coordinatorPhone: selectedBuilding["Phone"] || "",
          }
        : undefined,
    };

    setSelectedServices((current) => {
      const newServices = isEditing
        ? current.map((s) =>
            s.service?.currentService?.serviceID === editingServiceId
              ? serviceEntry
              : s
          )
        : [...current, serviceEntry];

      console.log("Services being sent from ServiceDesign:", newServices);
      // Notify parent component of the change
      onServiceChange(newServices);
      return newServices;
    });

    // Clear all selections and close dialog
    setSelectedBuilding(null);
    setSelectedZone("");
    setSelectedFloor("");
    setServiceQuantity(1);
    setIsAddDialogOpen(false);
    setCurrentService(null);
    setCurrentTasks([]);
    setIsEditing(false);
    setEditingServiceId(null);
    setError(null);
  };

  const handleCloseDialog = () => {
    setIsAddDialogOpen(false);
    setCurrentService(null);
    setCurrentTasks([]);
    setIsEditing(false);
    setEditingServiceId(null);
    setSelectedBuilding(null);
    setSelectedZone("");
    setSelectedFloor("");
    setServiceQuantity(1);
    setError(null);
  };

  const handleRemoveService = (serviceID) => {
    if (!serviceID) {
      console.error("No serviceID provided for removal");
      return;
    }

    console.log("Current services before removal:", selectedServices);
    console.log("Attempting to remove service with ID:", serviceID);

    setSelectedServices((current) => {
      // Find the service to be removed (for logging purposes)
      const serviceToRemove = current.find(
        (s) => s.service?.currentService?.serviceID === serviceID
      );

      if (!serviceToRemove) {
        console.warn("Service not found:", serviceID);
        return current;
      }

      // Create new array without the service to remove
      const newServices = current.filter(
        (serviceEntry) =>
          serviceEntry.service?.currentService.serviceID !== serviceID
      );

      console.log("Services after removal:", newServices);

      // Notify parent component of the change
      onServiceChange(newServices);

      return newServices;
    });
  };

  //Monitor Services Count
  useEffect(() => {
    console.log("Current Count of Selected Services:", selectedServices.length);
  }, [selectedServices]);

  //CleanUp
  useEffect(() => {
    return () => {
      setSelectedServices([]);
      setCurrentService(null);
      setCurrentTasks([]);
      setIsEditing(false);
      setError(null);
    };
  }, []);

  return (
    <Box sx={{ mt: 4, mb: 4 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: "bold",
              background:
                theme.palette.mode === "light"
                  ? "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)"
                  : "linear-gradient(45deg, #3f51b5 30%, #2196F3 90%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Project Services
          </Typography>
          <Chip
            label={selectedServices.length}
            sx={{
              fontWeight: "bold",
              fontSize: "1.1rem",
              height: "28px",
              background:
                theme.palette.mode === "light"
                  ? "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)"
                  : "linear-gradient(45deg, #3f51b5 30%, #2196F3 90%)",
              color: "white",
              boxShadow: "0 2px 4px rgba(33, 203, 243, .3)",
              transition: "all 0.3s ease",
              "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0 3px 6px rgba(33, 203, 243, .4)",
              },
            }}
          />
        </Box>
        <Button
          variant="contained"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => setIsAddDialogOpen(true)}
          sx={{
            background:
              theme.palette.mode === "light"
                ? "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)"
                : "linear-gradient(45deg, #3f51b5 30%, #2196F3 90%)",
            color: "white",
            boxShadow: "0 3px 5px 2px rgba(33, 203, 243, .3)",
            "&:hover": {
              background:
                theme.palette.mode === "light"
                  ? "linear-gradient(45deg, #1976D2 30%, #00B8D4 90%)"
                  : "linear-gradient(45deg, #283593 30%, #1976D2 90%)",
            },
          }}
        >
          Add Service
        </Button>
      </Box>

      {error && (
        <Alert
          severity="error"
          sx={{
            mb: 3,
            animation: "slideIn 0.3s ease-out",
            "@keyframes slideIn": {
              from: { transform: "translateY(-20px)", opacity: 0 },
              to: { transform: "translateY(0)", opacity: 1 },
            },
          }}
        >
          {error}
        </Alert>
      )}

      {selectedServices.length > 0 ? (
        <Grid container spacing={2}>
          {selectedServices.map((serviceEntry, index) => (
            <Grid
              item
              xs={12}
              key={`${serviceEntry.service.currentService.serviceID}-${index}`}
            >
              <ServiceCard
                serviceEntry={serviceEntry}
                index={index}
                totalServices={selectedServices.length}
                onEdit={handleEditService}
                onDelete={handleRemoveService}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Alert
          severity="info"
          sx={{
            mb: 2,
            backgroundColor:
              theme.palette.mode === "light"
                ? "rgba(33, 150, 243, 0.1)"
                : "rgba(33, 150, 243, 0.2)",
            border: "1px solid",
            borderColor: "primary.main",
            "& .MuiAlert-icon": {
              color: "primary.main",
            },
          }}
        >
          No services added to the project yet. Click 'Add Service' to begin.
        </Alert>
      )}

      <Dialog
        open={isAddDialogOpen}
        onClose={handleCloseDialog}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: theme.shadows[10],
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor:
              theme.palette.mode === "light" ? "primary.light" : "primary.dark",
            color:
              theme.palette.mode === "light" ? "primary.dark" : "primary.light",
            py: 2,
          }}
        >
          {isEditing ? "Edit Service" : "Add New Service"}
        </DialogTitle>
        <DialogContent sx={{ p: 3 }}>
          <Grid container spacing={3} sx={{ mt: 0 }}>
            <Grid item xs={12} md={6}>
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    mb: 2,
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    color: "primary.main",
                  }}
                >
                  <SettingsIcon fontSize="small" />
                  Available Services
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Search services..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  sx={{
                    mb: 2,
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "primary.main",
                      },
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
                <List
                  dense
                  sx={{
                    maxHeight: "400px",
                    overflow: "auto",
                    pr: 2,
                    "& .MuiListItem-root": {
                      transition: "all 0.2s ease",
                    },
                  }}
                >
                  {services
                    .filter(
                      (service) =>
                        service["Service Type"]
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase()) ||
                        service.Description.toLowerCase().includes(
                          searchQuery.toLowerCase()
                        ) ||
                        service.category
                          .toLowerCase()
                          .includes(searchQuery.toLowerCase())
                    )
                    .map((service) => (
                      <ListItem
                        key={service.serviceID}
                        disablePadding
                        sx={{ mb: 1 }}
                      >
                        <Button
                          fullWidth
                          variant={
                            currentService?.serviceID === service.serviceID
                              ? "contained"
                              : "outlined"
                          }
                          onClick={() => handleServiceSelect(service)}
                          sx={{
                            textAlign: "left",
                            justifyContent: "flex-start",
                            textTransform: "none",
                            p: 2,
                            borderRadius: 2,
                          }}
                        >
                          <Box>
                            <Typography variant="subtitle2">
                              {service["Service Type"]}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                              {service.Description}
                            </Typography>
                          </Box>
                        </Button>
                      </ListItem>
                    ))}
                </List>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    mb: 2,
                    fontWeight: "bold",
                    color: "primary.main",
                  }}
                >
                  Available Tasks
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="Search tasks..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  sx={{
                    mb: 2,
                    "& .MuiOutlinedInput-root": {
                      "&:hover fieldset": {
                        borderColor: "primary.main",
                      },
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon color="primary" />
                      </InputAdornment>
                    ),
                  }}
                />
                {currentService ? (
                  <List
                    dense
                    sx={{
                      maxHeight: "400px",
                      overflow: "auto",
                      pr: 2,
                      "& .MuiListItem-root": {
                        transition: "all 0.2s ease",
                        borderRadius: 1,
                      },
                    }}
                  >
                    {filteredTasks.map((task) => {
                      const isSelected = currentTasks.some(
                        (t) => t.ID === task.ID
                      );
                      return (
                        <ListItem
                          key={task.ID}
                          sx={{
                            mb: 1,
                            backgroundColor: isSelected
                              ? theme.palette.action.selected
                              : "transparent",
                            "&:hover": {
                              backgroundColor: isSelected
                                ? theme.palette.action.selected
                                : theme.palette.action.hover,
                            },
                          }}
                        >
                          <ListItemText
                            primary={task.name}
                            secondary={task.description}
                          />
                          <ListItemSecondaryAction>
                            <Tooltip
                              title={isSelected ? "Remove Task" : "Add Task"}
                            >
                              <IconButton
                                edge="end"
                                onClick={() => handleTaskToggle(task)}
                                color={isSelected ? "primary" : "default"}
                              >
                                {isSelected ? (
                                  <RemoveCircleOutlineIcon />
                                ) : (
                                  <AddCircleOutlineIcon />
                                )}
                              </IconButton>
                            </Tooltip>
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                ) : (
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      textAlign: "center",
                      py: 4,
                      fontStyle: "italic",
                    }}
                  >
                    Select a service to customize tasks
                  </Typography>
                )}
              </Box>
            </Grid>

            {currentService && (
              <Grid item xs={12}>
                <Divider sx={{ my: 2 }} />
                <Typography
                  variant="subtitle1"
                  sx={{
                    mb: 2,
                    fontWeight: "bold",
                    color: "primary.main",
                  }}
                >
                  Location Details
                </Typography>
                <BuildingSelector
                  buildings={availableBuildings}
                  selectedBuilding={selectedBuilding}
                  selectedZone={selectedZone}
                  selectedFloor={selectedFloor}
                  onBuildingChange={setSelectedBuilding}
                  onZoneChange={setSelectedZone}
                  onFloorChange={setSelectedFloor}
                  onQuantityChange={setServiceQuantity}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
          <Button
            onClick={handleCloseDialog}
            variant="outlined"
            sx={{
              borderRadius: 2,
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddService}
            variant="contained"
            disabled={!canUpdateService()}
            sx={{
              borderRadius: 2,
              background:
                theme.palette.mode === "light"
                  ? "linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)"
                  : "linear-gradient(45deg, #3f51b5 30%, #2196F3 90%)",
              "&:not(:disabled):hover": {
                background:
                  theme.palette.mode === "light"
                    ? "linear-gradient(45deg, #1976D2 30%, #00B8D4 90%)"
                    : "linear-gradient(45deg, #283593 30%, #1976D2 90%)",
              },
            }}
          >
            {isEditing ? "Update Service" : "Add Service"}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ServiceDesign;
