import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { 
  Card, 
  CardContent, 
  Box, 
  useTheme,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress
} from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { motion } from "framer-motion";
import TC_Header from "./TC_Header";
import TC_SkillsSection from "./TC_SkillsSection";
import TC_ProjectList from "./TC_ProjectList";
import TC_CalendarDialog from "./TC_CalendarDialog";
import TC_Error from "./TC_Error";
import { calculateAvailability } from "./availabilityCalculator";
import { fetchTechnicians, updateTechnicianSchedule } from "../../ApiServices/ApiServices";

// Loading Dialog Component
const UpdateDialog = ({ open, loading, onClose }) => (
  <Dialog
    open={open}
    onClose={loading ? undefined : onClose}
    PaperProps={{
      style: {
        minWidth: '300px',
        textAlign: 'center',
        padding: '20px'
      }
    }}
  >
    <DialogContent>
      {loading ? (
        <>
          <CircularProgress size={50} style={{ marginBottom: '20px' }} />
          <Typography>
            Updating Technician's Schedule...
          </Typography>
        </>
      ) : (
        <>
          <CheckCircleOutlineIcon 
            style={{ 
              fontSize: 60, 
              color: '#4CAF50',
              marginBottom: '20px' 
            }} 
          />
          <Typography>
            Project Deleted Successfully
          </Typography>
        </>
      )}
    </DialogContent>
    {!loading && (
      <DialogActions style={{ justifyContent: 'center' }}>
        <Button 
          onClick={onClose}
          variant="contained" 
          color="primary"
        >
          OK
        </Button>
      </DialogActions>
    )}
  </Dialog>
);

const TC_Main = ({
  technician,
  index,
  totalTechnicians,
  onAddMember,
  isSelected = false,
  startDate = null,
  endDate = null,
  onTechnicianUpdate,
}) => {
  const theme = useTheme();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDetailsLoaded, setIsDetailsLoaded] = useState(false);
  const [error, setError] = useState(null);
  
  // New state for update dialog
  const [updateDialog, setUpdateDialog] = useState({
    open: false,
    loading: false
  });

  const getCardColor = () => {
    const hue = (index / Math.max(totalTechnicians - 1, 1)) * 240;
    return theme.palette.mode === "light"
      ? `hsla(${hue}, 60%, 95%, 0.2)`
      : `hsla(${hue}, 60%, 25%, 0.2)`;
  };

  const getTextColor = () => {
    const hue = (index / Math.max(totalTechnicians - 1, 1)) * 240;
    return theme.palette.mode === "light"
      ? `hsl(${hue}, 60%, 30%)`
      : `hsl(${hue}, 60%, 70%)`;
  };
  const handleDeleteProject = async (projectNumber) => {
    try {
      if (!technician || !onTechnicianUpdate) {
        console.error("Missing required proper for deletion");
        return;
      }
      // Show loading dialog
      setUpdateDialog({ open: true, loading: true });
  
      // Find the schedule to delete
      const scheduleToDelete = technician.Scheduled.find(
        schedule => schedule.projectNumber === projectNumber
      );
  
      if (!scheduleToDelete) {
        throw new Error('Schedule not found');
      }
  
      // Prepare the payload for the API call
      const deletePayload = [{
        Email: technician.Email,
        Scheduled: [{
          projectNumber: scheduleToDelete.projectNumber,
          startDate: scheduleToDelete.projectDates.startDate,
          endDate: scheduleToDelete.projectDates.endDate
        }],
        operation: 'DELETE'
      }];
      
      
       
      // Make the API call
      const result = await updateTechnicianSchedule(deletePayload);
      console.log("Status Code: ", result.statusCode);
      if (!result.statusCode === 200) {
        throw new Error(result.message || 'Failed to delete project');
      }
  
      // Create a new Scheduled array without the deleted project
      const updatedSchedule = technician.Scheduled.filter(
        schedule => schedule.projectNumber !== projectNumber
      );
      
      console.log("Updated Schedule:", updatedSchedule);
      // Create a new technician object with the updated schedule
      const updatedTechnician = {
        Name: technician.Name,
        Email: technician.Email,
        Phone: technician.Phone,
        Utilization: technician.Utilization,
        Skills: technician.Skills,
        Scheduled: updatedSchedule,
        Role: technician.Role,
        availability: startDate && endDate ? calculateAvailability(
          startDate.format("YYYY-MM-DD"),
          endDate.format("YYYY-MM-DD"),
          updatedSchedule
        ) : technician.availability,
        skillScore: technician.skillScore,
        overallScore: technician.overallScore
      };

      setUpdateDialog({ open: true, loading: false });

      // Update the parent component with the new technician data
      onTechnicianUpdate(updatedTechnician);

    } catch (error) {
      console.error("Error deleting project:", error);
      setError(`Failed to delete project: ${error.message}`);
      setUpdateDialog({ open: true, loading: false });
    }
  };

const handleTechnicianCalendarUpdate = (updatedTechnician) => {
    if (onTechnicianUpdate) {
      const updatedWithAvailability = {
        ...updatedTechnician,
        availability: calculateAvailability(
          updatedTechnician.Scheduled,
          startDate?.format("YYYY-MM-DD"),
          endDate?.format("YYYY-MM-DD")
        ),
      };
      onTechnicianUpdate(updatedWithAvailability);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    >
      <Card
        sx={{
          marginBottom: 2,
          backgroundColor: getCardColor(),
          color: getTextColor(),
          transition: "all 0.3s ease",
        }}
      >
        <CardContent>
          <TC_Header
            technician={technician}
            getTextColor={getTextColor}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            setIsDetailsLoaded={setIsDetailsLoaded}
            startDate={startDate}
            endDate={endDate}
            onAddMember={onAddMember}
            isSelected={isSelected}
            setIsCalendarOpen={setIsCalendarOpen}
          />
          
          {isExpanded && (
            <Box sx={{ px: 2 }}>
              <TC_SkillsSection
                skills={technician.Skills}
                isDetailsLoaded={isDetailsLoaded}
                getTextColor={getTextColor}
              />
              <TC_ProjectList
                scheduled={technician.Scheduled}
                isDetailsLoaded={isDetailsLoaded}
                onDelete={handleDeleteProject}
              />
            </Box>
          )}
        </CardContent>
      </Card>

      <TC_CalendarDialog
        isOpen={isCalendarOpen}
        onClose={() => setIsCalendarOpen(false)}
        technician={technician}
        onTechnicianUpdate={handleTechnicianCalendarUpdate}
      />

      {/* Update Dialog */}
      <UpdateDialog
        open={updateDialog.open}
        loading={updateDialog.loading}
        onClose={() => setUpdateDialog({ open: false, loading: false })}
      />

      {/* Error handling modal */}
      <TC_Error 
        error={error} 
        onClose={() => setError(null)} 
      />
    </motion.div>
  );
};

TC_Main.propTypes = {
  technician: PropTypes.shape({
    Email: PropTypes.string.isRequired,
    Name: PropTypes.string.isRequired,
    Role: PropTypes.string,
    Skills: PropTypes.objectOf(PropTypes.number),
    Scheduled: PropTypes.arrayOf(
      PropTypes.shape({
        projectNumber: PropTypes.string.isRequired,
        projectName: PropTypes.string,
        projectDates: PropTypes.shape({
          startDate: PropTypes.string.isRequired,
          endDate: PropTypes.string.isRequired,
        }).isRequired,
      })
    ),
    availability: PropTypes.number,
    overallScore: PropTypes.number,
  }).isRequired,
  index: PropTypes.number.isRequired,
  totalTechnicians: PropTypes.number.isRequired,
  onAddMember: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  startDate: PropTypes.shape({
    format: PropTypes.func.isRequired,
  }),
  endDate: PropTypes.shape({
    format: PropTypes.func.isRequired,
  }),
  onTechnicianUpdate: PropTypes.func.isRequired,
};

export default TC_Main;