import dayjs from "dayjs";
import { v4 as uuidv4 } from 'uuid';

export const calculateAvailability = (scheduledDates, projectStart, projectEnd) => {
  if (!projectStart || !projectEnd) return 100;

  const start = dayjs(projectStart);
  const end = dayjs(projectEnd);

  if (!scheduledDates || scheduledDates.length === 0) return 100;

  const totalProjectDays = end.diff(start, "day") + 1;
  let unavailableDays = 0;

  scheduledDates.forEach((schedule) => {
    if (!schedule.projectDates) return;

    const scheduleStart = dayjs(schedule.projectDates.startDate);
    const scheduleEnd = dayjs(schedule.projectDates.endDate);

    if (scheduleEnd.isAfter(start) && scheduleStart.isBefore(end)) {
      const overlapStart = scheduleStart.isAfter(start) ? scheduleStart : start;
      const overlapEnd = scheduleEnd.isBefore(end) ? scheduleEnd : end;
      unavailableDays += overlapEnd.diff(overlapStart, "day") + 1;
    }
  });

  if (totalProjectDays <= 0) return 100;
  const availabilityPercentage =
    ((totalProjectDays - unavailableDays) / totalProjectDays) * 100;
  return Math.max(0, Math.min(100, availabilityPercentage));
};

export const calculateSkillScore = (skills) => {
  if (!skills) return 0;
  const skillValues = Object.values(skills);
  return skillValues.reduce((sum, value) => sum + value, 0) / skillValues.length;
};

export const processTechnicians = (techsData, startDate, endDate, selectedMembers) => {
  if (!Array.isArray(techsData) || !startDate || !endDate) {
    return { rankedTechnicians: [], filteredTechnicians: [] };
  }

  const processedTechs = techsData.map((tech) => {
    const availability = calculateAvailability(
      tech.Scheduled,
      startDate,
      endDate
    );
    const skillScore = tech.skillScore || calculateSkillScore(tech.Skills);
    const overallScore = (skillScore + availability) / 2;

    return {
      ...tech,
      availability,
      skillScore,
      overallScore,
    };
  });

  const rankedTechs = [...processedTechs].sort(
    (a, b) => b.overallScore - a.overallScore
  );

  const filteredTechs = rankedTechs.filter(
    (tech) => !selectedMembers.some((member) => member.Email === tech.Email)
  );

  return { rankedTechnicians: rankedTechs, filteredTechnicians: filteredTechs };
};

export const filterTechniciansBySearch = (rankedTechnicians, selectedMembers, searchInput) => {
  if (searchInput.trim() === "") {
    return rankedTechnicians.filter(
      (tech) => !selectedMembers.some((member) => member.Email === tech.Email)
    );
  }

  const searchTerm = searchInput.toLowerCase();
  return rankedTechnicians.filter(
    (tech) =>
      !selectedMembers.some((member) => member.Email === tech.Email) &&
      (tech.Name.toLowerCase().includes(searchTerm) ||
        tech.Email.toLowerCase().includes(searchTerm))
  );
};

export const validateProjectData = (projectData) => {
  const {
    projectName,
    selectedCustomer,
    startDate,
    endDate,
    selectedMembers,
    selectedServices
  } = projectData;

  if (!projectName.trim()) {
      throw new Error("Project name is required");
  }
  if (!selectedCustomer) {
    throw new Error("Customer selection is required");
  }
  if (!startDate || !endDate) {
    throw new Error("Both start and end dates are required");
  }
  if (endDate.isBefore(startDate)) {
    throw new Error("End date cannot be before start date");
  }
  if (selectedMembers.length === 0) {
    throw new Error("At least one team member is required");
  }
  if (selectedServices.length === 0) {
    throw new Error("At least one service is required");
  }
};

const DEFAULT_STATUS_TEMPLATE = {
  New: 0,
  Assigned: 0,
  InProgress: 0,
  OnHold: 0,
  Resolved: 0,
  Closed: 0,
  Cancelled: 0,
  Completed: 0
};

// Create a task template that inherits the service quantity
const createTaskTemplate = (taskName, quantity) => ({
  Name: taskName,
  Status: {
    ...DEFAULT_STATUS_TEMPLATE,
    New: quantity // Initialize all tasks as "New" with the service quantity
  },
  Count: quantity, // Set the count to match service quantity
  MinutesPer: 0,
  IDS: [],
  Assignees: []
});

export const createServiceTrackingTemplate = (serviceEntry) => {
  if (!serviceEntry || typeof serviceEntry !== 'object') {
    console.error('Invalid serviceEntry:', serviceEntry);
    return null;
  }

  const {
    service = {},
    tasks = [],
    quantity = 1,
    location = {}
  } = serviceEntry;

  const currentService = service.currentService || {};

  // Map the tasks maintaining their original IDs and names
  const formattedTasks = tasks.map(task => ({
    ID: task.ID,
    Name: task.name,
    Description: task.description,
    Status: {
      ...DEFAULT_STATUS_TEMPLATE,
      New: quantity
    },
    Count: quantity,
    MinutesPer: currentService.Minutes || 0,
    IDS: [],
    Assignees: []
  }));

  return {
    ID: currentService.serviceID || uuidv4(),
    ServiceName: currentService['Service Type'] || 'Unknown Service',
    ServiceCategory: currentService.category || 'Uncategorized',
    Cost: currentService['List Price'] || 0,
    MiscExpenses: currentService['Misc Expenses'] || 0,
    MinutesPerService: currentService.Minutes || 0,
    LastUpdated: [{
      User: "",
      Time: new Date().toISOString(),
      UpdateType: "Created"
    }],
    Owner: "",
    Status: {
      ...DEFAULT_STATUS_TEMPLATE,
      New: quantity
    },
    Count: quantity,
    IDS: [],
    Tasks: formattedTasks,
    Location: location?.building || "",
    Floor: location?.floor?.toString() || "",
    Zone: location?.zone || ""
  };
};

export const formatProjectData = (data) => {
  const {
    projectNumber,
    projectName,
    selectedCustomer,
    projectDescription,
    startDate,
    endDate,
    user,
    selectedMembers,
    selectedServices
  } = data;

  // Parse the selectedServices if it's a string
  let parsedServices = selectedServices;
  if (typeof selectedServices === 'string') {
    try {
      parsedServices = JSON.parse(selectedServices);
    } catch (error) {
      console.error('Error parsing services:', error);
      parsedServices = [];
    }
  }

  console.log('Parsed services:', parsedServices);

  const formattedServices = parsedServices.map(serviceEntry => {
    if (!serviceEntry) return null;

    const trackingTemplate = createServiceTrackingTemplate(serviceEntry);
    
    if (!trackingTemplate) return null;

    return {
      serviceDetails: {
        id: serviceEntry.service?.currentService?.serviceID || uuidv4(),
        type: serviceEntry.service?.currentService?.['Service Type'] || 'Unknown',
        category: serviceEntry.service?.currentService?.category || 'Uncategorized',
        description: serviceEntry.service?.currentService?.Description || '',
        vendor: serviceEntry.service?.currentService?.Vendor || '',
        manufacturer: serviceEntry.service?.currentService?.Manufacturer || '',
        sku: serviceEntry.service?.currentService?.SKU || '',
        listPrice: serviceEntry.service?.currentService?.['List Price'] || 0,
        miscExpenses: serviceEntry.service?.currentService?.['Misc Expenses'] || 0,
        minutes: serviceEntry.service?.currentService?.Minutes || 0
      },
      configuration: {
        quantity: serviceEntry.quantity || 1,
        location: serviceEntry.location || {},
      },
      tracking: trackingTemplate,
    };
  }).filter(Boolean);

  const finalProjectDetails = {
    projectDetails: {
      projectNumber,
      projectName,
      customer: selectedCustomer || "No Customer Selected",
      description: projectDescription || "No Description",
      dates: {
        start: startDate.format("YYYY-MM-DD"),
        end: endDate.format("YYYY-MM-DD"),
        created: dayjs().format("YYYY-MM-DD HH:mm:ss")
      }
    },
    team: {
      creator: {
        email: user?.EmailAddress || "No Email Address",
        createdAt: dayjs().format("YYYY-MM-DD HH:mm:ss")
      },
      members: selectedMembers.map((member) => ({
        email: member.Email,
        name: member.Name,
        skillScore: member.skillScore,
        availability: member.availability,
      }))
    },
    services: formattedServices,
    metadata: {
      version: "1.0",
      lastModified: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      status: "Active"
    }
  };

  // Debug log
  console.log('Final Project Details:', JSON.stringify(finalProjectDetails, null, 2));

  return finalProjectDetails;
};