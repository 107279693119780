// ServiceCard.js
import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  IconButton,
  Chip,
  Tooltip,
  Grid,
  Avatar,
  useTheme,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EngineeringIcon from '@mui/icons-material/Engineering';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { motion } from 'framer-motion';

const ServiceCard = ({ serviceEntry, index, totalServices, onEdit, onDelete }) => {
  const theme = useTheme();

  const handleDelete = () => {
    console.log("Attempting to delete service:", {
      id: serviceEntry.service.currentService.serviceID,
      type: serviceEntry.service.currentService["Service Type"]
    });
    onDelete(serviceEntry.service.currentService.serviceID);
  }

  // Create a color gradient based on the service index
  const getCardColor = () => {
    const hue = (index / Math.max(totalServices - 1, 1)) * 180; // Using 180 for a blue-green spectrum
    return theme.palette.mode === 'light'
      ? `hsla(${hue}, 70%, 95%, 0.8)`
      : `hsla(${hue}, 70%, 25%, 0.8)`;
  };

  const getAccentColor = () => {
    const hue = (index / Math.max(totalServices - 1, 1)) * 180;
    return theme.palette.mode === 'light'
      ? `hsl(${hue}, 70%, 40%)`
      : `hsl(${hue}, 70%, 60%)`;
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3, delay: index * 0.1 }}
    >
      <Card
        elevation={2}
        sx={{
          mb: 2,
          background: `linear-gradient(135deg, ${getCardColor()}, ${theme.palette.background.paper})`,
          borderLeft: `6px solid ${getAccentColor()}`,
          transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
          '&:hover': {
            transform: 'translateY(-2px)',
            boxShadow: theme.shadows[4],
          },
        }}
      >
        <CardContent>
          <Grid container spacing={2}>
            <Grid item>
              <Avatar
                sx={{
                  bgcolor: getAccentColor(),
                  width: 56,
                  height: 56,
                }}
              >
                <EngineeringIcon />
              </Avatar>
            </Grid>
            
            <Grid item xs>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <Box>
                  <Typography variant="h6" sx={{ color: getAccentColor(), fontWeight: 'bold' }}>
                    {serviceEntry.service.currentService["Service Type"]}
                  </Typography>
                  <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                    {serviceEntry.service.currentService.category}
                  </Typography>
                </Box>
                <Box>
                  <Tooltip title="Edit Service">
                    <IconButton onClick={() => onEdit(serviceEntry)} size="small">
                      <EditIcon sx={{ color: getAccentColor() }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Service">
                    <IconButton 
                      onClick={handleDelete}
                      size="small"
                      sx={{ 
                        '&:hover': { 
                          color: theme.palette.error.main 
                        }
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>

              <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
                {serviceEntry.service.currentService.Description}
              </Typography>

              {serviceEntry.location && (
                <Box
                  sx={{
                    mt: 2,
                    p: 1.5,
                    borderRadius: 1,
                    bgcolor: theme.palette.mode === 'light' 
                      ? 'rgba(0,0,0,0.03)' 
                      : 'rgba(255,255,255,0.03)',
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      color: getAccentColor(),
                    }}
                  >
                    <LocationOnIcon fontSize="small" />
                    Location Details
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Building: {serviceEntry.location.building} ({serviceEntry.location.buildingNumber})
                    {serviceEntry.location.zone && ` • Zone: ${serviceEntry.location.zone}`}
                    {serviceEntry.location.floor && ` • Floor: ${serviceEntry.location.floor}`}
                  </Typography>
                  {serviceEntry.location.buildingCoordinator && (
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                      Coordinator: {serviceEntry.location.buildingCoordinator}
                      {serviceEntry.location.coordinatorEmail && ` • ${serviceEntry.location.coordinatorEmail}`}
                    </Typography>
                  )}
                </Box>
              )}

              <Box sx={{ mt: 2, display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                <Chip 
                  icon={<CheckCircleIcon />}
                  label={`${serviceEntry.quantity} ${serviceEntry.quantity > 1 ? 'services' : 'service'}`}
                  size="small"
                  sx={{ 
                    bgcolor: getAccentColor(),
                    color: 'white',
                  }}
                />
                {serviceEntry.tasks.map((task) => (
                  <Chip
                    key={task.ID}
                    label={task.name}
                    size="small"
                    variant="outlined"
                    sx={{
                      borderColor: getAccentColor(),
                      color: getAccentColor(),
                    }}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </motion.div>
  );
};

export default ServiceCard;