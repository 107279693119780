import React from 'react';
import {
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Autocomplete,
    Box,
    Typography,
} from '@mui/material';

const BuildingSelector = ({
    buildings,
    selectedBuilding,
    selectedZone,
    selectedFloor,
    onBuildingChange,
    onZoneChange,
    onFloorChange,
    serviceQuantity,
    onQuantityChange,
}) => {
    const getFloorOptions = (floorCount) => {
        if (!floorCount) return [1]; // Default to 1 if no floors specified
        return Array.from({ length: floorCount }, (_, i) => i + 1);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Autocomplete
                    options={buildings || []}
                    value={selectedBuilding}
                    onChange={(event, newValue) => onBuildingChange(newValue)}
                    getOptionLabel={(option) => option["Building Name"] || ''}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Building"
                            fullWidth
                            required
                        />
                    )}
                    renderOption={(props, option) => {
                        const { key, ...otherProps } = props;
                        return (
                            <Box
                                key={option["Building Number"]}
                                component="li"
                                {...otherProps}
                            >
                                <Box sx={{ py: 1 }}>
                                    <Typography variant="body1">
                                        {option["Building Name"]}
                                    </Typography>
                                    <Typography variant="caption" color="text.secondary">
                                        Building Number: {option["Building Number"]} 
                                        {option["Building Coordinator"] && ` • Coordinator: ${option["Building Coordinator"]}`}
                                    </Typography>
                                </Box>
                            </Box>
                        );
                    }}
                    isOptionEqualToValue={(option, value) => 
                        option["Building Number"] === value["Building Number"]
                    }
                />
            </Grid>

            {selectedBuilding?.Zone && (
                <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                        <InputLabel>Zone</InputLabel>
                        <Select
                            value={selectedZone || ''}
                            onChange={(e) => onZoneChange(e.target.value)}
                            label="Zone"
                        >
                            <MenuItem value={selectedBuilding.Zone}>
                                {selectedBuilding.Zone}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            )}

            <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                    <InputLabel>Floor</InputLabel>
                    <Select
                        value={selectedFloor || 1}
                        onChange={(e) => onFloorChange(e.target.value)}
                        label="Floor"
                    >
                        {getFloorOptions(selectedBuilding?.Floors || 1).map((floor) => (
                            <MenuItem key={floor} value={floor}>
                                Floor {floor}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    label="Quantity"
                    type="number"
                    value={serviceQuantity}
                    onChange={(e) => {
                        const value = parseInt(e.target.value);
                        if (value >= 1 && value <= 9999) {
                            onQuantityChange(value);
                        }
                    }}
                    InputProps={{
                        inputProps: { min: 1, max: 9999 }
                    }}
                    helperText="Enter quantity (1-9999)"
                />
            </Grid>
        </Grid>
    );
};

export default BuildingSelector;